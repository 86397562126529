<template>
  <div id="members-list">
    <v-toolbar>
      Members
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="membersEvent"
      class="elevation-0 border table-event-control-members"
      :search="search"
      :loading="showTableLoader"
      :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
      :options.sync="options"
      item-key="_key"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="membersEvent.length"
      :footer-props="footerOptions"
      sortable
      :multi-sort="false"
      @pagination="onPagination"
      :single-expand="true"
      :expanded.sync="membersEvent"
    >
      <template v-slot:top> </template>
      <template v-slot:[`item.name`]="{ item }">
        <div>{{ item.engine ? item.engine.name : "" }}</div>
      </template>
      <template v-slot:[`item.serial_number`]="{ item }">
        <div>{{ item.engine ? item.engine.serial_number : "" }}</div>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ item.role ? item.role.name : "" }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:[`item.menu`]="{ item }">
        <!--        <v-tooltip top>-->
        <!--          <template v-slot:activator="{ on }">-->
        <!--            <v-btn-->
        <!--              @click.stop="deleteMember(item)"-->
        <!--              :color="Pallette.actionButtons.delete"-->
        <!--              dark-->
        <!--              fab-->
        <!--              x-small-->
        <!--              v-on="on"-->
        <!--            >-->
        <!--              <v-icon>mdi-delete</v-icon>-->
        <!--            </v-btn>-->
        <!--          </template>-->
        <!--          <span>Delete member</span>-->
        <!--        </v-tooltip>-->
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <EventControlPlaylists :member="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EventControlPlaylists from "./EventControlPlaylists"

export default {
  components: {
    EventControlPlaylists,
  },

  data() {
    return {
      selectedEnginesKeys: [],
      search: "",
      loading: false,
      options: {
        sortBy: ["name", "serial_number"],
        page: 1,
        itemsPerPage: 10,
        group_key: null,
      },
      headers: [
        {
          text: "Name",
          value: "name",
          width: 200,
          sortable: false,
        },
        {
          text: "Serial number",
          value: "serial_number",
          width: 200,
          sortable: false,
        },
        {
          text: "Role",
          value: "role",
          width: 200,
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          width: 200,
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          width: 200,
          sortable: false,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 150,
        },
      ],
      filters: {
        app_version: [],
      },
      footerOptions: {
        "items-per-page-options": [5, 10, 15, 20, 30],
      },
      expanded: [],
    }
  },

  methods: {
    onPagination() {
      this.selectedEnginesKeys = this.activeEvent.members || []
    },
    deleteMember(item) {
      this.$store.dispatch("deleteEventMember", item)
    },
  },

  computed: {
    ...mapGetters({
      activeEvent: "activeEvent",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      engines: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
    membersEvent: {
      get() {
        return this.activeEvent.members
      },
      set(val) {},
    },
  },
}
</script>

<style lang="scss">
.td-event-role {
  vertical-align: center;
  .v-icon {
    visibility: hidden;
  }

  &:hover {
    text-decoration: dashed underline;

    .v-icon {
      visibility: visible;
    }
  }
}
</style>

<style lang="scss">
.table-event-control-members {
  .v-data-table__expanded.v-data-table__expanded__row td {
    background-color: #272727;
  }

  .v-data-table__expanded {
    &.v-data-table__expanded__content .alert {
      padding-left: 40px;
    }
    &.v-data-table__expanded__content td {
      &:first-child {
        padding-left: 40px;
      }

      background-color: #424242;
    }
  }
}
</style>
