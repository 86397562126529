var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.member.playlist &&
      _vm.member.status &&
      _vm.member.status.toLowerCase() === 'joined'
    )?_c('v-simple-table',{staticStyle:{"border-radius":"0"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticStyle:{"width":"100%"}},[_c('td',{staticClass:"text-left",staticStyle:{"width":"320px"}},[_vm._v(" Project: "+_vm._s(_vm.member.playlist.project)+" ")]),_c('td',{staticClass:"text-left",staticStyle:{"width":"200px","overflow-wrap":"break-word","max-width":"200px"}}),_c('td',{staticClass:"text-left",staticStyle:{"width":"200px","overflow-wrap":"break-word","max-width":"200px"}}),_c('td',{staticClass:"text-left",staticStyle:{"width":"200px","overflow-wrap":"break-word","max-width":"200px"}}),_c('td',{staticClass:"text-right",staticStyle:{"width":"150px"}},[(
                _vm.isSuperUser ||
                _vm.$can('rc.close', 'ws_rc') ||
                _vm.$can('rc.playback', 'ws_rc') ||
                _vm.$can('rc.playlist', 'ws_rc')
              )?_c('v-btn',{staticClass:"white--text mr-3",attrs:{"small":"","color":"blue-grey","to":`/engines/remote-control/engine/${_vm.member.engine.name}/project/${_vm.member.playlist.project}/${_vm.member.playlist._key}?event=${_vm.activeEvent._key}`}},[_vm._v(" View playlists ")]):_vm._e()],1)])])]},proxy:true}],null,false,2749636969)}):_c('div',[_c('span',{staticClass:"alert grey--text text--lighten-1"},[_vm._v(" No active playlist ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }