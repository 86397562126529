<template>
  <div>
    <v-form data-vv-scope="form-event" key="eventCreateUpdate">
      <v-card>
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
          <v-spacer></v-spacer>
          <span v-if="activeEvent.is_started" class="green--text pr-3"
            >Started</span
          >
          <span v-if="!activeEvent.is_started" class="red--text pr-3"
            >Not Started</span
          >
        </v-card-title>
        <v-card-text>
          <!--          <v-btn-->
          <!--            small-->
          <!--            color="blue-grey"-->
          <!--            class="white&#45;&#45;text ml-3"-->
          <!--            :to="'/engines/remote-control/engine/'+item._engine.name+'/project/'+item.project+'/'+item._key"-->
          <!--            v-if="$can('read', 'ws_rc') || $can('read', 'Superadmin')"-->
          <!--          >-->
          <!--            View playlists-->
          <!--          </v-btn>-->

          <v-row>
            <v-col sm="12" md="2" class="pt-0">
              <v-list two-line>
                <v-list-item key="country-city">
                  <v-list-item-avatar>
                    <v-icon> mdi-city </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ countryName }}</v-list-item-title>
                    <v-list-item-subtitle
                      v-html="cityName"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col sm="12" md="2" class="pt-0">
              <v-list two-line>
                <v-list-item key="start-end">
                  <v-list-item-avatar>
                    <v-icon> mdi-calendar-clock </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      activeEvent.start_date
                        | unixDateFormatTimezone(activeEvent.time_zone)
                    }}</v-list-item-title>
                    <v-list-item-title>{{
                      activeEvent.end_date
                        | unixDateFormatTimezone(activeEvent.time_zone)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col
              sm="12"
              md="8"
              align="right"
              class="pt-0"
              v-if="
                $can('event.start', 'ws_event') ||
                $can('event.stop', 'ws_event')
              "
            >
              <v-btn
                v-if="!activeEvent.is_started"
                @click="startStopCurrEvent(true)"
                :color="Pallette.actionButtons.play"
                :loading="loadingStartStopEvent"
              >
                <v-icon left> mdi-play </v-icon>
                Start current event
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-if="activeEvent.is_started"
                @click="startStopCurrEvent(false)"
                :color="Pallette.actionButtons.delete"
                :loading="loadingStartStopEvent"
              >
                <v-icon left> mdi-stop </v-icon>
                End current event
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <EventControlMembers />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import WsConnMixin from "@/mixins/websocket-connection.js"
import EventControlMembers from "./EventControlMembers"
import EventCommonMixin from "@/mixins/event/common.js"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    EventControlMembers,
  },

  mixins: [GeneralMixin, WsConnMixin, EventCommonMixin],

  data: () => ({
    activeTab: null,
    modal: false,
  }),

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetEventItem").then(() => {
      self.getItemData().then(() => {
        self.$store
          .dispatch("setCompanyForEvent", {
            editedIndex: self.editedIndex,
            groupKey: self.currentUser.group_key,
          })
          .then(() => {
            self.$store.dispatch("saveLoaderAction", false)
          })
      })
    })
    // there are some changes in member statuses - we need to update them
    self.$root.$on("event-page-refresh", (data) => {
      self.$store.dispatch("refreshEventMembersStatuses", {
        slug: self.editedIndex,
      })
    })
    self.$on("events-subscribe-playlist", (data) => {
      self.$store.dispatch("refreshEventMembersPlaylists", data)
    })
  },

  mounted() {
    let self = this
    self.initWebsocketConnection({ playlist: true })
    this.activeTab = this.baseRoute
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Create new event"
        : `<span class="blue-grey--text text--lighten-4">Control event</span> ${this.activeEvent.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getEventItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    startStopCurrEvent(action = false) {
      let self = this
      self.$store.dispatch("loadingStartStopEvent", true).then(() => {
        self.$root.$emit("eStartStopCurrentEvent", action)
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEvent: "activeEvent",
      ordersRegisteredList: "ordersRegisteredList",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      Pallette: "get_Pallette",
      loadingStartStopEvent: "loadingStartStopEvent",
    }),
    isActiveConnectionsTab() {
      return this.isSuperUser && !this.activeEvent.group_key
    },
    baseRoute() {
      return this.editedIndex === -1
        ? "/store/event/create"
        : `/store/event/edit/${this.editedIndex}`
    },
    countryName() {
      return this.activeEvent?.country?.name
        ? this.activeEvent?.country?.name
        : "No location"
    },
    cityName() {
      return this.activeEvent?.city?.name
    },
  },
}
</script>
