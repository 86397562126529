<template>
  <div>
    <v-simple-table
      v-if="
        member.playlist &&
        member.status &&
        member.status.toLowerCase() === 'joined'
      "
      style="border-radius: 0"
    >
      <template v-slot:default>
        <tbody>
          <tr style="width: 100%">
            <td class="text-left" style="width: 320px">
              Project: {{ member.playlist.project }}
            </td>
            <td
              class="text-left"
              style="width: 200px; overflow-wrap: break-word; max-width: 200px"
            ></td>
            <td
              class="text-left"
              style="width: 200px; overflow-wrap: break-word; max-width: 200px"
            ></td>
            <td
              class="text-left"
              style="width: 200px; overflow-wrap: break-word; max-width: 200px"
            ></td>
            <td class="text-right" style="width: 150px">
              <v-btn
                small
                color="blue-grey"
                class="white--text mr-3"
                :to="`/engines/remote-control/engine/${member.engine.name}/project/${member.playlist.project}/${member.playlist._key}?event=${activeEvent._key}`"
                v-if="
                  isSuperUser ||
                  $can('rc.close', 'ws_rc') ||
                  $can('rc.playback', 'ws_rc') ||
                  $can('rc.playlist', 'ws_rc')
                "
              >
                View playlists
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>
      <span class="alert grey--text text--lighten-1"> No active playlist </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    member: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
      activeEvent: "activeEvent",
    }),
  },
}
</script>
